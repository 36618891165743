var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "prices", attrs: { fluid: "" } },
    _vm._l(_vm.prices, function (plan, i) {
      return _c(
        "v-card",
        { key: i, staticClass: "plan-block mb-2 mt-2 pa-3" },
        [
          _c("div", { staticClass: "plan-content" }, [
            _c("h3", [_vm._v(_vm._s(_vm.$t(`prices.${plan}.title`)))]),
            _c("p", [_vm._v(_vm._s(_vm.$t(`prices.${plan}.content`)))]),
          ]),
          _c(
            "div",
            { staticClass: "plan-footer" },
            [
              _c("v-btn", {
                staticClass: "ma-2",
                staticStyle: { width: "8rem" },
                attrs: {
                  color: "blue white--text",
                  disabled: _vm.isPriceRequested(i),
                },
                domProps: { textContent: _vm._s(_vm.$t("Get price")) },
                on: {
                  click: function ($event) {
                    return _vm.requestPrice(i)
                  },
                },
              }),
            ],
            1
          ),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }