var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "fill-height" },
    [
      _c(
        "v-container",
        { staticClass: "password-reset" },
        [
          _c(
            "v-row",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-col",
                { attrs: { lg: "4", md: "6", sm: "12" } },
                [
                  _vm.token
                    ? _c(
                        "v-card",
                        [
                          _c("v-card-title", [_vm._v(" Password Reset ")]),
                          _c("v-card-subtitle", [
                            _vm._v(
                              " Your password must be at least " +
                                _vm._s(_vm.MIN_PASS_LENGTH) +
                                " characters including lowercase and uppercase letters, and digits "
                            ),
                          ]),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "form",
                                  model: {
                                    value: _vm.valid,
                                    callback: function ($$v) {
                                      _vm.valid = $$v
                                    },
                                    expression: "valid",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      rules: _vm.passRules,
                                      type: "password",
                                      label: _vm.$t("Password"),
                                    },
                                    model: {
                                      value: _vm.password,
                                      callback: function ($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      rules: _vm.repeatRules,
                                      type: "password",
                                      label: _vm.$t("Repeat Password"),
                                    },
                                    model: {
                                      value: _vm.repeat,
                                      callback: function ($$v) {
                                        _vm.repeat = $$v
                                      },
                                      expression: "repeat",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    color: "primary",
                                    loading: _vm.loading,
                                  },
                                  on: { click: _vm.submit },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("Set New Password")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.reaction.message
                            ? _c(
                                "v-card-text",
                                {
                                  staticClass: "body-1 error white--text pt-4",
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.reaction.message) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "v-card",
                        [
                          _c("v-card-title", [
                            _vm._v(" " + _vm._s(_vm.$t("Invalid token")) + " "),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }