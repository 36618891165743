import { render, staticRenderFns } from "./DemoUserRoleNotification.vue?vue&type=template&id=4413d99f"
import script from "./DemoUserRoleNotification.vue?vue&type=script&lang=js"
export * from "./DemoUserRoleNotification.vue?vue&type=script&lang=js"
import style0 from "./DemoUserRoleNotification.vue?vue&type=style&index=0&id=4413d99f&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/arkotik/Projects/wingle/wims-fe/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4413d99f')) {
      api.createRecord('4413d99f', component.options)
    } else {
      api.reload('4413d99f', component.options)
    }
    module.hot.accept("./DemoUserRoleNotification.vue?vue&type=template&id=4413d99f", function () {
      api.rerender('4413d99f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DemoUserRoleNotification.vue"
export default component.exports