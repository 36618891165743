var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { staticClass: "demo-user-notification" }, [
    _vm._v(" 🚀 "),
    _c("span", [
      _vm._v(
        " " +
          _vm._s(
            _vm.$t(
              "DEMO User: Please be aware, your access is restricted to a set number of items in each module.Certain filters may also have limitations."
            )
          ) +
          " "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }