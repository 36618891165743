var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      { staticClass: "welcome-blue-top" },
      [
        _c("v-container", [
          _c("header", [
            _c("h1", [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("Innovation product monitoring platform"))
                ),
              ]),
            ]),
            _c("h2", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "Stay updated with latest technologies and products developments"
                  )
                )
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "d-flex justify-center flex-wrap" },
            _vm._l(_vm.categories, function (category, key) {
              return _c(
                "div",
                {
                  key: key,
                  class: { "my-8 mx-4": !_vm.smDown, "my-4": _vm.smDown },
                  style: { width: _vm.smDown ? "50%" : "min-content" },
                },
                [
                  _c(
                    "v-list-item",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-item-icon",
                        { class: { "mt-4 mr-4": !_vm.xs, "mr-1": _vm.xs } },
                        [
                          _c("icon-marker", {
                            attrs: {
                              view: "icon",
                              iconsize: _vm.xs
                                ? "small"
                                : _vm.smDown
                                ? ""
                                : "x-large",
                              item: { category },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        { staticClass: "white--text" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "font-weight-bold mb-0",
                              class: {
                                xs: "title",
                                "display-1": _vm.smDown && !_vm.xs,
                                "display-2": !_vm.smDown,
                              },
                              staticStyle: { "white-space": "nowrap" },
                            },
                            [
                              _vm.counters[category] !== null
                                ? _c("AnimatedNum", {
                                    attrs: {
                                      number: _vm.counters[category],
                                      time: 800,
                                      fps: 60,
                                    },
                                  })
                                : _c("v-progress-circular", {
                                    attrs: { indeterminate: "" },
                                  }),
                            ],
                            1
                          ),
                          _c("div", {
                            staticClass: "title",
                            style: {
                              fontSize: _vm.xs
                                ? "0.7rem !important"
                                : _vm.smDown
                                ? "1rem !important"
                                : "1.25rem !important",
                            },
                            domProps: {
                              textContent: _vm._s(_vm.$t(`cat.${category}`)),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "body-1 text-left px-4 mx-auto",
              staticStyle: { "max-width": "60rem" },
            },
            [
              _c("h3", { staticClass: "title pb-2" }, [
                _vm._v(_vm._s(_vm.$t("Managing Information The Smart Way"))),
              ]),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("managingInformationParagraph1"))),
              ]),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("managingInformationParagraph2"))),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-center pt-4" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-2",
                  staticStyle: { width: "8rem" },
                  attrs: { large: "", color: "primary" },
                  on: { click: _vm.Interface.login.show },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Login")) + " ")]
              ),
              _c("v-btn", {
                staticClass: "ma-2",
                staticStyle: { width: "8rem" },
                attrs: { large: "", color: "blue white--text" },
                domProps: { textContent: _vm._s(_vm.$t("Prices")) },
                on: {
                  click: function ($event) {
                    return _vm.Interface.navTo("/prices")
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _c(
      "section",
      { staticClass: "welcome-white-mid" },
      [
        _c(
          "v-card",
          { attrs: { flat: "", tile: "" } },
          [
            _c("v-container", { staticClass: "py-12" }, [
              _c(
                "div",
                {
                  staticClass: "body-1 px-4 mx-auto",
                  staticStyle: { "max-width": "60rem" },
                },
                [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "display-2 font-weight-bold text-center pb-4",
                    },
                    [_vm._v(_vm._s(_vm.$t("How Do We Collect Information?")))]
                  ),
                  _c("div", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("collectingInformationProccessParagraph1")
                        )
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("collectingInformationProccessParagraph2")
                        )
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }