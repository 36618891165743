var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _vm.loaded
        ? [
            _vm.isDemoUserRole ? _c("DemoUserRoleNotification") : _vm._e(),
            _vm.isUserLoggedIn
              ? _c(
                  "v-main",
                  { staticClass: "main-container" },
                  [_c("router-view")],
                  1
                )
              : _vm.$route.path == "/"
              ? _c(
                  "PublicTemplate",
                  [_c("router-view", { attrs: { name: "public" } })],
                  1
                )
              : _c("PublicTemplate", [_c("router-view")], 1),
            _c("dialog-login", {
              on: { "reset-password": _vm.showResetModal },
            }),
            _c("dialog-register"),
            _c(
              "v-dialog",
              {
                attrs: { "max-width": "420px" },
                model: {
                  value: _vm.resetModal,
                  callback: function ($$v) {
                    _vm.resetModal = $$v
                  },
                  expression: "resetModal",
                },
              },
              [
                _vm.resetModal
                  ? _c("reset-password-request-form", {
                      on: {
                        cancel: function ($event) {
                          _vm.resetModal = false
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("ToastStack"),
          ]
        : _vm._e(),
      _c("Reporter"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }